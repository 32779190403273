/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import { ResponsiveWidthContainer } from "../components/Section";
import { DefaultButton, Button } from "../components/Buttons";

export const ButtonBlockContainer = styled(ResponsiveWidthContainer)`
  button {
    display: block;
  }
`;

const TestPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ButtonBlockContainer bgColor="#fff">
        <DefaultButton>Default Button Instance</DefaultButton>
        <hr />
        <Button>Primary</Button>
        <Button color="primary" outline>
          Primary Outline
        </Button>
        <Button color="primary" large>
          Primary Large
        </Button>
        <Button color="primary" outline large>
          Primary Large Outline
        </Button>
        <hr />

        <Button color="secondary">Secondary</Button>
        <Button color="secondary" outline>
          Secondary Outline
        </Button>
        <Button color="secondary" large>
          Secondary Large
        </Button>
        <Button color="secondary" large outline>
          Secondary Large Outline
        </Button>
        <hr />

        <Button color="basic">Basic</Button>
        <Button color="basic" outline>
          Basic Outline
        </Button>
        <Button color="basic" large>
          Basic Large
        </Button>
        <Button color="basic" large outline>
          Basic Large Outline
        </Button>
      </ButtonBlockContainer>
    </Layout>
  );
};

export default TestPage;
